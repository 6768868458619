.header-slider {
  padding-top: 50px;
/*  .focuspoint {
	img {
	  position: relative;
	}
  }*/
  .keyvis-height {
    height: 23vw;
    @include media-breakpoint-down(lg) {
      height: 43vw;
    }
    @media (max-width: 991.98px) and (orientation:landscape) {
      height: 31vw;
    }
    @include media-breakpoint-down(sm) {
      height: 62.5vw;
    }
    @include media-breakpoint-between(sm, sm) {
      height: 35vw !important;
    }
    &.withparams {
      @include media-breakpoint-down(sm) {
        height: 100vw;
      }
    }
  }
  .swiper-container {
    @include media-breakpoint-down(md) {
      max-height: 95vh;
    }
  }

  .keyvisualtext {
	position: absolute;
	left: 0;
	top: 60px;
	padding: 1.25rem 1.875rem;
	width: 450px;
	@include media-breakpoint-down(lg) {
	  width: 360px;
    padding: 1rem 1.5rem;
    top: 62px;
    h1,h2 {
      font-size: 1.3rem;
    }
	}
  @include media-breakpoint-down(md) {
    width: 338px;
    padding: .9rem 1.375rem;
    top: 42px;
    h1,h2 {
      font-size: 1.1875rem;
    }
  }
  @include media-breakpoint-down(sm) {
    width: 274px;
    padding: .9rem 1.375rem;
    top: 24px;
    h1,h2 {
      font-size: 16px;
    }
  }
  @media (max-width: 320px) {
    width: 88%;
  }
	&:before {
	  position: absolute;
	  content: "";
	  background: $bluedark;
	  opacity: .75;
	  width: 100%;
	  height: 100%;
	  left:0;
	  top:0;
	  z-index: 0;
	}
    h1 {
      color: $white;
    }
    h2 {
      color: $white;
    }
  }
  .kwrap {
	z-index: 1;
	position: relative;
  }

  .swiper-pagination-bullet {
	background-color: $white;
	opacity: 1;
  }
  .swiper-pagination-bullet-active {
	background-color: $bluedark;
  }
  .swiper-button-next {
	right: 40px;
  }
  .swiper-button-prev {
	left: 40px;
  }
  .swiper-button-next,.swiper-button-prev {
	margin-top: 30px;
	width: 30px;
	height: 30px;
	background-image: none;
	background-size: auto;
	background: $gray-600;
	color: $white;
	line-height: 1rem;
	text-align: center;
    @include media-breakpoint-down(md) {
      margin-top: -10px;
    }
	.svg-icon {
	  width: 1.8rem;
	  height: 1.8rem;
	  --color1: #ffffff;
	}
	&:hover {
	  background: $primary;
	  .svg-icon {
		--color1: #ffffff;
	  }
	}
  }
  .parameters {
	position: absolute;
	bottom: 1.5rem;
	left: 0;
	right: 0;
	z-index: 1;
	color: $white;
  padding: 5px 30px;
	@include media-breakpoint-down(lg) {
	  font-size: .7rem;
    max-width: 87%;
    padding: 0 24px;
    bottom: 1.75rem;
	}
  @include media-breakpoint-down(md) {
    font-size: .875rem;
    max-width: 90%;
    padding: 0 22px;
    bottom: 1rem;
  }
    @include media-breakpoint-between(sm, sm) {
    max-width: 96% !important;
  }
  @include media-breakpoint-down(sm) {
    font-size: 14px;
    max-width: 88%;
    padding: 0 20px;
    bottom: 1rem;
  }
	.counter-item {
    @include media-breakpoint-between(sm, md) {
      width: initial !important;
    }
	  @include media-breakpoint-down(sm) {
		width: 50%;
	  }
	}
	.counter-count {
	  font-size: 2.2em;
	  line-height: 1.55;
	  font-family: $font-family-bold;
    position: relative;
    @include media-breakpoint-down(lg) {
      font-size: 2.4rem;
    }
    @include media-breakpoint-down(md) {
      font-size: 2.375rem;
    }
    @include media-breakpoint-down(sm) {
      font-size: 28px;
    }
	}
	.counter_label {
	  padding: 0.5rem 1rem 1rem;
	  position: relative;
    @include media-breakpoint-down(lg) {
      padding: 0.5rem 1rem;
    }
	  &:before {
		height: 1px;
		position: absolute;
		content: "";
		background: $white;
		width: 100%;
		left: 50%;
		top: 0;
		transform: translateX(-50%);
		@include media-breakpoint-down(md) {
		  width: 130px;
		}
    @include media-breakpoint-down(sm) {
      width: 100px;
    }
	  }
	  font-family: $font-family-bold;
	  a {
		color: $white;
		&:hover {
		  color: $white;
		  text-decoration: none;
		}
	  }
	}
    &:before {
      position: absolute;
      content: "";
      background: $bluedark;
      opacity: .75;
      width: 100%;
      height: 100%;
      left:0;
      top:0;
      z-index: 0;
    }

    .counter_container {
      a {
        color: $white;
        text-decoration: none;
      }
    }
  }

  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0;
  }

}
