.hypocalc {
  .ui-slider-horizontal .ui-slider-handle {
	border-radius: 0.6em;
	top: -0.5em;
	border-color: $gray-440;
  }
  .ui-widget.ui-widget-content {
	height: 2px;
	border: none;
	background-color: $gray-440;
	border-left: .45rem solid #36728C;
  }
  .ui-widget-header {
	background-color: $bluedark !important;
  }
  .ui-widget-content .ui-state-hover,
  .ui-widget-content .ui-state-focus {
	background-color: $bluedark !important;
  }
  input {
	width: 100%;
  }
}
