.teaser {
  .teaserimage{
    img{
      @include media-breakpoint-down(sm) {
        position: relative;
      }
    }
  }
  a {
    &:hover {
      text-decoration: underline;
      color: $body-color;
      .link-arrow {
      text-decoration: underline;
      color: $body-color;
      }
    }
      @include media-breakpoint-down(md) {
      .link-arrow {
      text-decoration: underline;
      color: $primary;
      }
    }
  }

  .mb-3,
  .my-3 {
    margin-bottom: 0rem !important;
    margin-top: 0rem !important;
  }
}
