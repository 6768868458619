.tiles {
  .card-body {
	padding: 1.25rem 1.25rem 1.875rem 1.25rem;
  }
  .card-deck {
	margin: 0 -5px;
  }
  .card {
	padding: 0;
	margin: 5px;
	min-width: 25%;
	.svg-icon {
	  width: 3.5rem;
	  height: 3.5rem;
	}
	&:hover {
	  text-decoration: none;
	  background: $primary;
	  color: $white;
	  .svg-icon {
		--color1: #ffffff;
      --color1neg: #3b7d98;
	  }
	  h5 {
		color: $white;
	  }
	}
  }
}
