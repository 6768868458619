.tx-pkbs-contacts {
  p:last-child {
	margin: 0;
  }
  .list-group-item {
	border-top: none;
	background-color: transparent;
	&:hover,
	&.active {
	  background-color: transparent;
	  color: $bluedark;
	  border-color: $bluedark;
	}
  }
  .list-group-flush:last-child .list-group-item:last-child {
	border-bottom: 1px solid $gray-450;
	&:hover,
	&.active {
	  background-color: transparent;
	  color: $bluedark;
	  border-color: $bluedark;
	}
  }
}