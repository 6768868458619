.navigation-wrap {
  width: 100%;
  @include media-breakpoint-down(md) {
    max-height: calc(100vh - 50px);
    overflow-y: auto;
  }
  #navbarNav {
    z-index: $zindex-sticky;
    background: $white;
    @include media-breakpoint-down(md) {
      padding: 0;
      border-top: 1px solid $primary;
    }
    .nav {
      width: 100%;
      list-style-type: none;
    }
    .desktop {
      .nav-item {
        flex-basis: 0;
        &.active,
        &:hover {
          .main-menu-item:before {
            width: 100%;
          }
        }
        .main-menu-item {
          position: relative;
          margin: 15px 20px;
          font-family: $font-family-bold;
          font-size: 1.0625rem;
          color: $primary;
          padding: .8rem .5rem;
          &:before,
          &:after {
            bottom: 0;
          }
        }
        .sub-menu li a {
          display: block;
          &.active,
          &:hover {
            font-family: $font-family-bold;
          }
          &.active {
            color: $primary;
          }
          &:before {
            display: block;
            content: attr(title);
            font-weight: bold;
            height: 0;
            overflow: hidden;
            visibility: hidden;
            font-family: $font-family-bold;
          }
        }
      }
    }
    .mobile {
      .nav-item {
        position: relative;
        &.active {
          .subtrigger {
            background: $bluedark !important;
            color: $white !important;
            .svg-icon {
              --color1: #ffffff !important;
            }
          }
        }
        .subtrigger {
          position: absolute;
          font-size: 2.5rem;
          top: 0px;
          bottom: 1px;
          right: 0px;
          width: 80px;
          @include media-breakpoint-down(md) {
            width: 60px;
          }
          /*display: none;*/
          text-align: center;
          vertical-align: middle;
          background: $bluedark;
          color: $white;
/*          &:before {
            content: "";
            display: block;
            position: absolute;
            background-color: $gray-600;
            width: 1px;
            top: 10px;
            bottom: 10px;
            left: 0;
          }*/
          .svg-icon {
            --color1: #36728C;
            height: 56px;
            width: 56px;
            &.active {
              --color1: #36728C;
            }
          }
          &.collapsed {
            color: $bluedark;
            background-color: $graymobile;
            .svg-icon {
              --color1: #36728C;
            }
            &:hover {
              background: $bluedark;
              color: $white;
              .svg-icon {
                --color1: #ffffff;
              }
            }
          }
          cursor: pointer;
          z-index: 2;
          /*@include media-breakpoint-down(md) {
			top: 5px;
		  }*/
          .fal,.svg-icon {
            transition: transform 0.3s ease-in-out;
          }
          &.collapsed {
            .fal,.svg-icon {
              transform: rotate(180deg);
            }
          }
          /*
          &:hover {
            color: $bluedark;
            .svg-icon {
              --color1: #36728C;
            }
          }*/
        }
        .nav-link {
          white-space: nowrap;
          color: $bluedark;
          font-size: 1.18rem;
          font-family: $font-family-bold;
          padding: 1rem 1rem 1rem 1rem;
          position: relative;
          &.has-sub {
            padding-right: 90px;
            @include media-breakpoint-down(md) {
              padding-right: 70px;
            }
/*            &:before {
              display: none;
              content: "";
              background: $white;
              right: 0;
              top: 0;
              bottom: 0;
              z-index: 1;
              width: 79px;
              @include media-breakpoint-down(md) {
                width: 59px;
              }
              height: calc(100% - 1px);
              position: absolute;
            }
            &:hover {
              &:before {
                display: block;
              }
            }*/
          }
          /*
          &.active {
            color: $bluedark;
          }
        */
          &.active {
            + .sub-menu {
              min-width: 100%;
              transition: all 0.35s ease-in-out;
            }
          }
          &:after {
            height: 1px;
            width: 100%;
            content: "";
            line-height: 1px;
            display: block;
            position: absolute;
            left:0;
            bottom: 0;
            background: $bluedark;
          }
        }
      }
      .sub-menu {
        .nav-item.active {
          .nav-link {
            background: $bluedark;
            color: $white;
          }
        }
        .nav-link {
          padding-left: 2rem;
        }
        .sub-menu {
          .nav-link {
            padding-left: 3rem;
          }
        }
      }
    }
  }
  #navbarNavfooter {
    background: none;
    z-index: 0;
    .nav {
      width: 100%;
      list-style-type: none;
    }
    .nav-item {
      border-top: 1px solid $gray-450;
      .subtrigger {
        position: absolute;
        font-size: 2.5rem;
        top: 0px;
        bottom: 0;
        right: 0px;
        width: 60px;
        /*@include media-breakpoint-down(md) {
          padding-right: 60px;
        }*/
        /*display: none;*/
        text-align: center;
        vertical-align: middle;
        .svg-icon {
          --color1: #999999;
          height: 56px;
          width: 56px;
          &.active {
            --color1: #36728C;
          }
        }
        color: $gray-500;
        z-index: 2;
        .fal,.svg-icon {
          transition: transform 0.3s ease-in-out;
        }
      }
      .nav-link {
        white-space: nowrap;
        color: $gray-600;
        @include media-breakpoint-down(md) {
          color: $black !important;
        }
        font-size: $h5-font-size;
        padding: 1rem 60px 1rem 1rem;
        position: relative;
        &.collapsed {
          .fal,.svg-icon {
            transform: rotate(180deg);
          }
        }
        &:before {
          display: block;
          content: attr(title);
          font-weight: bold;
          height: 0;
          overflow: hidden;
          visibility: hidden;
          font-family: $font-family-bold;
        }
        &:hover {
          color: $bluedark;
          .svg-icon {
            --color1: #36728C;
          }
        }
      }
    }
  }
}
