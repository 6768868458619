.nbar {
  padding: 0;
  @include box-shadow($box-shadow-topbar);
  z-index: 10000;
}


.logo {
  margin: 5px 0;
  display: block;
  width: 144px;
  @include media-breakpoint-down(sm) {
	margin: 5px 0;
	height: 40px;
	line-height: 40px;
	width: auto;
  display: inline-block;
	img {
	  height: 100%;
    max-width: 100%;
	}
  }
}
.hamburger {
  line-height: 0;
  transition: none !important;
  &:hover {
    opacity: 1 !important;
  }
}
.hamburger-box {
  width: 30px;
}
.hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
  height: 2px;
  background-color: $gray-600;
  width: 30px;
}
.metanav {
  .nav-link {
	/*color: $gray-800;*/
	font-size: $font-size-base * 1.059;
  }
}
#mtnav {
  .nav-link {
    padding-right: 25px;
  }
}
#searchboxbottom {
  #top-search-form {
	margin: 47px 0 $grid-gutter-width/2 0;
  }
}
#tx-solr-search,
#top-search-form {
  .tx-solr-q {
	box-shadow: none;
  }
  .btn {
	background: $white;
  }
}
#searchboxtop {
	#top-search-form {
	  margin: 0 !important;
	}
  @include media-breakpoint-down(sm) {
	order: 5;
	background: $gray-100;
	flex-basis: 100%;
	width: 100%;
	#top-search-form {
	  margin: 1rem !important;
	}
  }
}

.animbnt-wrap {
  display: inline-block;
  vertical-align: -7px;
}
.quick-search-circle {
  position: relative;
  border: 2px $gray-600 solid;
  width: 22px;
  height: 22px;
  top: 0px;
  left: 0;
  border-radius: 50%;
  transition:
		  height 300ms linear,
		  width 300ms linear,
		  left 300ms linear,
		  top 300ms linear,
		  transform 300ms linear,
		  border 20ms linear 0s;
  box-sizing: border-box;
  backface-visibility: hidden;
  @include media-breakpoint-down(md) {
    border: 2px $white solid;
  }
}

.quick-search-bar {
  position: relative;
  margin-top: -6px;
  height: 12px;
  left: 0;
  width: 3px;
  background: $gray-600;
  border-radius: 2px;
  transition: all 300ms linear;
  transform: rotate(45deg);
  @include media-breakpoint-down(md) {
    background: $white;
  }
}

#mtnav.on {
  .quick-search-circle {
	width: 28px;
	height: 2px;
	left: 0px;
	top: 11px;
	transform: rotate(45deg);
	border-radius: 1px;
	border-width: 1px;
	transition:
			height 300ms linear,
			width 300ms linear,
			left 300ms linear,
			top 300ms linear,
			transform 300ms linear,
			border 20ms linear 300ms;

  }
  .quick-search-bar {
	height: 28px;
	width: 2px;
	top: 1px;
	left: 12px;
	transform: rotate(45deg);
	margin-top: -5px;
  }
}

.animbtn {
  border: none;
  width: auto;
  height: 100%;
}
#mtshadow {
  order: 3;
  position: relative;
  z-index: 1;
  height: 1px;
  @include box-shadow($box-shadow-topbar);
}
