.contactbar {
  flex-direction: column;
  .phoicon {
	cursor: pointer;
  }
  &.shwn {
	transform: translateX(0%);
	@media (max-width:1220px) {
	  transform: translate(0%,0%);
	}
  }
  transform: translateX(100%);
  transition: all 0.35s ease-in-out;
  right: 0;
  top: 40vh;
  border-bottom: 1px solid $border-color;
  border-top: 1px solid $border-color;
  padding: 1rem .25rem;
  position: fixed;
  z-index: 9000;
  @media (max-width:1220px) {
	flex-direction: row;
	transform: translate(0,100%);
	padding: .25rem 1rem;
	top: auto;
	bottom: 0;
	left: 0;
	background: $white;
	border: none;
	@include box-shadow($topshadow);
  }
  .cbar-icon {
	display: block;
	font-size: 2rem;
	color: $gray-600;
	padding: .15rem .25rem .15rem .45rem;
	@media (max-width:1220px) {
	  padding: .15rem .25rem;
	}
	text-align: center;
	&.active,
	&:hover {
	  text-decoration: none;
	  color: $primary;
	  i {
		font-weight: 600;
	  }
	}
  }
  #pinfowrap {
	position: absolute;
	transform: translate(-100%,-50%);
	top: 50%;
	left: -0.35rem;
	background: $gray-100;
	@media (max-width:1220px) {
	  width: 100%;
	  position: relative;
	  transform: none;
	  padding: 1rem 0.35rem;
	  top: 0;
	  left: 0;
	  background: none;
	}
  }
  #phoneinfo {
	display: flex;
	border-bottom: 1px solid $border-color;
	border-top: 1px solid $border-color;
	@media (max-width:1220px) {
	  text-align: center;
	  border-bottom: none;
	  border-top: none;
	}
	padding: 0.25rem 0.35rem;
	color: $body-color;
	font-size: 1rem;
	align-items: center;
	white-space: nowrap;
	.closebtn {
	  font-size: 2rem;
	  cursor: pointer;
	  .svg-icon {
		--color1: #36728C;
	  }
	  @media (max-width:1220px) {
		position: absolute;
		right: 0px;
		top: 0px;
	  }
	}
  }
  .svg-icon {
    --color1: #787878;
    &:hover {
      --color1: #36728C;
    }
  }
}
