.uploads {
  border-top: 1px solid $primary;
  a {
	/*@include box-shadow($generalshadow);*/
	border-bottom: 1px solid $primary;
	/*background: $white;*/
	color: $primary;
	font-family: $font-family-bold;
	.svg-icon {
	  width: 2.38rem;
	  height: 2.38rem;
	  --color1: #36728C;
	}
	&:hover {
	  background: $primary;
	  color: $white;
	  /*@include box-shadow($generalshadowactive);*/
	  .svg-icon {
		--color1: #ffffff;
	  }
	}
  }

}
