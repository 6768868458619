.ajax-page-overlay,
.page-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  background: $overlay-background;
  z-index: 9999;
  display: none;

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
    width: $spinner-width;
    height: $spinner-height;
    color: $primary;
  }
  &.show {
    display: block;
  }
  &.menu {
    .spinner {
      display: none;
    }
  }
}
.ajax-page-overlay {
  z-index: 11000;
}