.card-text-bg {
  position: relative;
  &:before {
  	position: absolute;
	content: "";
	top:0;
	left:0;
	right:0;
	bottom:0;
	background: $white;
	z-index: 0;
	opacity: 0.9;
  }
  .date {
	margin-right: .5rem;
	&:after {
	  content: "|";
	  display: inline-block;
	  margin-left: .5rem;
	}
  }
}
.newsitem {
  @include media-breakpoint-down(md) {
	.link-arrow {
	  text-decoration: underline;
	  color: $body-color;
	}
	.card-img-overlay {
	  position: relative;
	}
	.card-text-bg {
	  width: 100% !important;
	}
	.collapse {
	  display: block !important;
	}
  }
  a:hover {
	text-decoration: none;
	.card__content-top,
	.card-text {
	  color: $body-color !important;
	}
  }
  .link-arrow {
	color: $body-color;
  }
  .link-arrow:hover {
	text-decoration: underline;
	color: $body-color;
  }
  img {
	/*max-height: 210px !important;*/
	@include media-breakpoint-down(md) {
	  max-height: unset !important;
	}
  }
  .card-link-overlay {
    text-decoration: none;
  }
}
