.main-footer {
  margin-top: 80px;
  .h6 {
	font-size: 0.8235rem !important;
	color: $body-color;
  }
}
.fbarnav {
  .nav-item {
	&:before {
	  display: inline-block;
	  content: "|";
	  position: absolute;
	}
	.nav-link {
	  color: $white;
	  text-decoration: underline;
	  &:hover {
		color: $white;
	  }
	}
  }
}
.text-small {
  font-size: $font-size-sm;
}
.f-nav {

}