#searchboxtop {
  max-width: 330px;
  @include media-breakpoint-down(md) {
	max-width: unset;
  }
  #top-search-form {
	@include media-breakpoint-down(md) {
	  margin: $grid-gutter-width/2;
	}
  }
}

.tx_solr {
  .btn.border-primary {
	.far,
	.fal {
	  color: $primary;
	}
  }
  .search-nav,
  .search-results {
	padding: $grid-gutter-width/2;
  }

  .search-results.person {
	margin: 0 $grid-gutter-width/2;
  }
  .table th {
	border-top: none;
	border-bottom-width: 1px;
  }
  .nav-item {
	&:first-child {
	  .nav-link {
		margin-left: 0;
	  }
	}
	&:last-child {
	  .nav-link {
		margin-right: 0;
	  }
	}
  }
  .nav-link {
	&:hover {
	  text-decoration: none;
	}
	margin: 0 1rem;
	padding: 1rem;
	position: relative;
	&:after {
	  display: block;
	  content: "";
	  height: 1px;
	  position: absolute;
	  bottom: 13px;
	  left: 0px;
	  right: 0px;
	  background: $primary;
	  z-index: 0;
	}
	&:before {
	  display: block;
	  content: "";
	  height: 2px;
	  position: absolute;
	  bottom: 13px;
	  left: 0px;
	  right: 0px;
	  background: $primary;
	  width: 0;
	  transition: all 0.5s ease-in-out;
	  z-index: 1;
	}
	&.active,
	&:hover {
	  &:before {
		width: 100%;
	  }
	  .menu-button {
		color: $primary;
	  }
	}
	.menu-button {
	  font-size: 1.0625rem;
	  color: $primary;
	  background: transparent;
	  font-family: $font-family-bold;

	}
  }
}

.flyouts {
  top: 60px;
  @include media-breakpoint-down(md) {
	top: 55px;
	z-index: 99999;
  }
  left: 0;
  right: 0;
  position: fixed;
  z-index: 9999;
}
.flyout-search {
  .tx-solr-autosuggest {
	position: relative !important;
  }
}
.gicon {
 	.btn {
    padding-right: 10px;
    padding-left: 0;
     .search-icon{
       margin: 0;
     }
  }
}
.flyout-close {
  cursor: pointer;
  .svg-icon {
	width: 50px;
	height: 50px;
	--color1: #36728C;
  }
}

/*.autocomplete-suggestions {
  .search-result {
	margin-bottom: 30px;
  }
  .group .right-content {
	background: $primary;
	border: 1px solid $primary;
	min-height: 132px;
	color: $white;
	a {
	  color: $white;
	}
	&:hover {
	  background: $white;
	  color: $primary;
	  text-decoration: none;
	  a {
		color: $primary;
		text-decoration: none;
	  }
	}
  }
  .left-content {
	.autocomplete-suggestion {
	  padding: .5rem .75rem;
	  background: $gray-250;
	  margin-bottom: 4px;
	  &:last-child {
		margin-bottom: 0;
	  }
	  .left-content--text {
		margin: 0;
	  }
	  a {
		display: block;
		color: $body-color;
		&:hover {
		  color: $primary;
		  text-decoration: none;
		  font-family: $font-family-bold;
		}
	  }
	}
  }
}*/

#tab_tx_pkbscontacts_domain_model_contact {
  margin: 0 -15px;
}

.autocomplete-suggestions {
  z-index: 10000 !important;
  background: $white;
  border: 1px solid $border-color;
  border-top: none;
  @include box-shadow($boxshadow);
  .autocomplete-suggestion {
    padding: 0.5rem 1rem;
    &.autocomplete-selected,
    &:hover{
      background: $bluedark;
      cursor: pointer;
      color: $white;
    }
  }
}
