.accessmenu {
  background: none;
  cursor: pointer;
  /*margin-bottom: 1rem !important;*/
  .main-menu-item {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    &:before,
    &:after {
      bottom: 0;
    }
  }
}

.main-menu-item {
  /*background: $white;*/
  /*border: 1px solid $gray-440;*/
  /*@include box-shadow($generalshadow);*/
  margin: $card-group-margin $card-group-margin !important;
  &.layout-1 {
	background: transparent;
	box-shadow: none;
	&:hover {
	  background: transparent;
	  box-shadow: none;
	  .menu-button {
		display: inline-block;
		color: $bluedark;
		&:hover {
		  text-decoration: none;
		}
	  }
	}
  }
  &:hover {
	/*background: $gray-450;*/
	text-decoration: none;
	/*@include box-shadow($generalshadowactive);*/
	&:before {
	  width: 100%;
	}
  }
  &:before,
  &:after {
	display: block;
	content: "";
	height: 1px;
	position: absolute;
	bottom: 13px;
	left: 0px;
	right: 0px;
	background: $bluedark;
  }
  &:before {
	height: 2px;
	right: unset;
	width: 0;
	transition: all 0.5s ease-in-out;
  }
  @include media-breakpoint-down(md) {
	min-width: 45%;
  }
  @include media-breakpoint-down(sm) {
	width: 100%;
  }
  @include media-breakpoint-down(xs) {
	margin: 0.5rem 0 !important;
  }
  .menu-button {
	font-size: 1.0625rem;
	color: $primary;
	background: transparent;
	font-family: $font-family-bold;
	display: inline-block;
	&:hover {
	  text-decoration: none;
	}
  }
}
.layout-0.main-menu-item,
.accessSubmenu .main-menu-item {
  margin: $card-group-margin*2 $card-group-margin*2 !important;
  &:first-child {
	margin-left: $card-group-margin !important;
  }
  &:last-child {
	margin-right: $card-group-margin !important;
  }
}
.submenu-item {
  position: relative;
  display: inline-block;
  padding: 0 2rem;
  color: $primary;
  &:hover{
	font-family: $font-family-bold;
	text-decoration: none;
  }
  &:after {
	font-family: $font-family-base !important;
	position: absolute;
	content: "|";
	display: inline-block;
	right: 0;
	transform: translateX(50%);
	color: $primary;
  }
  &:before {
	display: block;
	content: attr(title);
	font-weight: bold;
	height: 0;
	overflow: hidden;
	visibility: hidden;
	font-family: $font-family-bold;
  }
  &:last-child:after {
	display: none;
  }
}
