@font-face{
    font-family:"Univers Next W01 Bold";
    src:url("#{$font-path}/777445/365d926e-03e5-4406-a883-fd0f9e462339.eot?#iefix");
    src:url("#{$font-path}/777445/365d926e-03e5-4406-a883-fd0f9e462339.eot?#iefix") format("eot"),url("#{$font-path}/777445/043eef6e-81ef-483c-831c-7bfd6628554b.woff2") format("woff2"),url("#{$font-path}/777445/7b11912a-986d-462e-b88a-4a1758eb23f1.woff") format("woff"),url("#{$font-path}/777445/545a8d94-3389-418c-9b0a-bfc16d1f8285.ttf") format("truetype");
    font-display: swap;
}
@font-face{
    font-family:"Univers Next W01 Light";
    src:url("#{$font-path}/777460/63aa74f9-a724-4074-85c7-4167c0448227.eot?#iefix");
    src:url("#{$font-path}/777460/63aa74f9-a724-4074-85c7-4167c0448227.eot?#iefix") format("eot"),url("#{$font-path}/777460/97c0f83d-39ac-412b-9d8a-d98208c0ce8d.woff2") format("woff2"),url("#{$font-path}/777460/fc09acb4-f628-4ba0-b648-d2ca30265b20.woff") format("woff"),url("#{$font-path}/777460/3cb4004c-2eea-45d4-a0b5-7af6f817c451.ttf") format("truetype");
    font-display: swap;
}
@font-face{
    font-family:"UniversNextW01-LightIta";
    src:url("#{$font-path}/777463/8c7fe4ab-375c-4be5-bba8-2c4d83ac807a.eot?#iefix");
    src:url("#{$font-path}/777463/8c7fe4ab-375c-4be5-bba8-2c4d83ac807a.eot?#iefix") format("eot"),url("#{$font-path}/777463/b37595d7-d2c2-465d-81bd-f5bc289fc565.woff2") format("woff2"),url("#{$font-path}/777463/88e0673d-3db0-4931-ae03-8a4a6eb1bbe6.woff") format("woff"),url("#{$font-path}/777463/957860fc-f472-47cc-afcb-6305b3455894.ttf") format("truetype");
    font-display: swap;
}