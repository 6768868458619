.pensioncalc {
  .pensiondate:focus + .input-group-append .btn {
	border-color: $primary;
  }
  .input-group {
	.btn {
	  padding: 0;
	  border-color: $input-border-color;
	  .svg-icon {
		width: 38px;
		height: 38px;
		--color1: #36728C;
		@include media-breakpoint-down(md) {
		  height: 32px;
		}
	  }
	  &:hover {
		.svg-icon {
		  --color1: #ffffff;
		}
	  }
	}
  }
}

/*mobile*/
@media (max-width: 768px) {

	.tx-pkbs-pension-calculator {
		.fa-info-circle {
			align-self: flex-end;
			margin-bottom: 7px;
		}

	}

}
