.contactbox {
  a {
	background: $primary;
	color: $white;
	.svg-icon {
	  width: 2.2rem;
	  height: 2.2rem;
	  --color1: #ffffff;
	}
	&:hover {
	  background: $white;
	  color: $primary;
	  .svg-icon {
		--color1: #36728C;
	  }
	}
  }
}
