// this is the place for your components / CSS
/* Typo3 Spaces */
.frame-space-before-extra-small { margin-top: 1rem !important; }
.frame-space-before-small { margin-top: 2rem !important; }
.frame-space-before-medium { margin-top: 3rem !important; }
.frame-space-before-large { margin-top: 4rem !important; }
.frame-space-before-extra-large { margin-top: 6rem !important; }
.frame-space-after-extra-small { margin-bottom: 1rem !important; }
.frame-space-after-small { margin-bottom: 2rem !important; }
.frame-space-after-medium { margin-bottom: 3rem !important; }
.frame-space-after-large { margin-bottom: 4rem !important; }
.frame-space-after-extra-large { margin-bottom: 6rem !important; }

@include media-breakpoint-down(sm) {
  .frame-space-before-extra-small { margin-top: 1rem !important; }
  .frame-space-before-small { margin-top: 2rem !important; }
  .frame-space-before-medium { margin-top: 3rem !important; }
  .frame-space-before-large { margin-top: 4rem !important; }
  .frame-space-before-extra-large { margin-top: 6rem !important; }
  .frame-space-after-extra-small { margin-bottom: 1rem !important; }
  .frame-space-after-small { margin-bottom: 2rem !important; }
  .frame-space-after-medium { margin-bottom: 3rem !important; }
  .frame-space-after-large { margin-bottom: 4rem !important; }
  .frame-space-after-extra-large { margin-bottom: 6rem !important; }
}

@include media-breakpoint-down(md) {
  .frame-space-before-extra-small { margin-top: 1rem !important; }
  .frame-space-before-small { margin-top: 2rem !important; }
  .frame-space-before-medium { margin-top: 3rem !important; }
  .frame-space-before-large { margin-top: 4rem !important; }
  .frame-space-before-extra-large { margin-top: 6rem !important; }
  .frame-space-after-extra-small { margin-bottom: 1rem !important; }
  .frame-space-after-small { margin-bottom: 2rem !important; }
  .frame-space-after-medium { margin-bottom: 3rem !important; }
  .frame-space-after-large { margin-bottom: 4rem !important; }
  .frame-space-after-extra-large { margin-bottom: 6rem !important; }
}

$generalshadow: 0 2px 8px rgba($black, .08) !default;
$topshadow: 0 0 8px rgba($black, .3) !default;
$generalshadowactive: 0 2px 8px rgba($black, .3) !default;

html,body {
  overflow: hidden;
}
html {
  overflow-y: scroll;
  font-size: 1.0625rem !important;
}

b, strong {
  font-family: $font-family-bold;
  font-weight: $font-weight-base;
}

dl, ol, ul {
  padding-left: 1rem;
}
ul {
  list-style: none;
  list-style-type: none;
}
ol.blue-number {
  list-style: none;
  li::before {
    content: "." counter(list);
    color: $primary;
    display: inline-block;
    margin-left: -1em;
    margin-right: 0.2em;
    text-align: right;
    direction: rtl
  }
  li {
    counter-increment: list
  }
}
@for $i from 1 through 100 {
  ol.blue-number[start="#{$i+1}"] {
    counter-reset: list $i ;
  }
}

ol.blue-number {
  li::before {
    color: $primary;
  }
}
ol.black-number {
  li::before {
    color: $body-color;
  }
}

ul.black-dot li,
ol.dotted li {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
}
ul.black-dot li,
ul.dotted li {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
ul.black-dot li:before,
ul.dotted li:before {
  content: "";
  color: $bluedark;
  font-weight: 600;
  display: inline-block;
  margin-left: -1rem;
  /*margin-right: 0.6rem;*/
  font-size: 0.4rem;
  width: 1rem;
  vertical-align: 4px;
  font-family: 'Font Awesome 5 Pro';
}
ul.black-dot li:before {
  color: $black;
}
ul:last-child,ol:last-child,p:last-child {
  margin-bottom: 0;
}

h1, .h1 {
  color:$h1-color;
  margin: 1.5rem 0;
}
h3, .h3 {
  color:$h3-color;
  font-weight: 100;
}
h4, .h4 {
  font-weight: 100;
}
h5, .h5 {
  color:$h5-color;
  margin-bottom: 22px;
}
h6, .h6 {
  color:$h6-color;
  margin-bottom: 0.9411rem;
}
h2,h4 {
  font-family: "Univers Next W01 Light";
}

button {
  border: none;
  &:focus {
    outline: none;
  }
}

.btn {
  box-shadow: none !important;
  &:focus {
    outline: none;
    box-shadow: none !important;
  }
}

/*
.btn .svg-icon,
.btn .fal,
.btn .far,
.btn .fas {
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}*/

.btn {
  transition: none;
}

.btn-primary:hover {
  background: $white;
  color: $primary;
  border-color: $primary;
}

.container {
  @include media-breakpoint-down(lg) {
    max-width: 100%;
  }
}

.svg-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.main-container {
  &.top-space {
    margin-top: 61px;
    @include media-breakpoint-down(sm) {
      margin-top: 110px;
    }
  }
}

.page-link {
  border-radius: 25px;
  min-width: 50px;
  font-size: 1.1875rem;
  text-align: center;
  color: $bluedark;
}
.page-item {
  &.next,
  &.prev {
    .page-link {
      border: none;
      background: transparent;
      padding: 0;
      &:hover{
        border: none;
        background: transparent;
      }
      .svg-icon {
        width: 50px;
        height: 50px;
        --color1: #36728C;
        --color1neg: #fff;
      }
    }
  }
}

.frame-leadtext {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
  @include media-breakpoint-down(md) {
    max-width: 100%;
  }
}

.breadcrumb-item {
  font-size: .823rem;
  a {
    color: $body-color;
  }
}
.frame-whitebox {
  padding: 1rem;
  background: $white;
  @include media-breakpoint-down(md) {
    margin-bottom: 1rem;
  }
}
.search-icon,
.print-icon {
  cursor: pointer;
  margin-right: 8px;
  .svg-icon {
    width: 50px;
    height: 50px;
    --color1: #3E3E3E;
    --color1neg: #fff;
  }
  &:hover {
    .svg-icon {
      --color1: #36728C;
      --color1neg: #fff;
    }
  }
}
.content-container {
  @include media-breakpoint-down(md) {
    padding: 0;
  }
}

.right-content {
  p {
    padding: 0;
  }
}

.btn {
  font-weight: $font-weight-bold;
}

.btn-light {
  border-color: $gray-500;
  box-shadow: none;
  &:hover {
    border-color: $gray-500;
  }
}
.ui-widget-header{
  background: $gray-500 !important;
}
.ui-widget-content .ui-state-active,
.ui-widget-content .ui-state-hover,
.ui-widget-content .ui-state-focus {
  background-color: $gray-500 !important;
}

.ui-icon,
.ui-widget-content .ui-icon {
  background-image: url("#{$image-path}ui-icons_444444_256x240.png");
}
.ui-widget-header .ui-icon {
  background-image: url("#{$image-path}ui-icons_444444_256x240.png");
}
.ui-state-hover .ui-icon,
.ui-state-focus .ui-icon,
.ui-button:hover .ui-icon,
.ui-button:focus .ui-icon {
  background-image: url("#{$image-path}ui-icons_555555_256x240.png");
}
.ui-state-active .ui-icon,
.ui-button:active .ui-icon {
  background-image: url("#{$image-path}ui-icons_ffffff_256x240.png");
}
.ui-state-highlight .ui-icon,
.ui-button .ui-state-highlight.ui-icon {
  background-image: url("#{$image-path}ui-icons_777620_256x240.png");
}
.ui-state-error .ui-icon,
.ui-state-error-text .ui-icon {
  background-image: url("#{$image-path}ui-icons_cc0000_256x240.png");
}
.ui-button .ui-icon {
  background-image: url("#{$image-path}ui-icons_777777_256x240.png");
}

.link-arrow {
  display: inline-block;
  position: relative;
  &:after {
    position: relative;
    display: inline;
    content: " ";
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    font-size: 0.85rem;
    line-height: 1.5rem;
  }
}
a.link-arrow {
  @include media-breakpoint-down(md) {
    text-decoration: underline;
    color: $body-color;
  }
  &:hover {
    text-decoration: underline;
    color: $body-color;
  }
}
a.in-text {
  position: relative;
  display: inline-block;
  text-decoration: underline;
  @include media-breakpoint-down(md) {
    color: $body-color;
  }
  &:hover {
    color: $body-color;
    text-decoration: underline;
  }
}
/*
.contact-item,
.search-nav,
.search-results,
.img-shadow,
.frame-whitebox,
.link-listitem,
.card {
  @include box-shadow($generalshadow);
}*/

.link-listitem {
  font-family: $font-family-bold;
}

.imgplaceholder {
  min-width: 190px;
  min-height: 122px;
  width: 100%;
  height: auto;
  background: $gray-500;
}

.contact-item {
  /*padding: 0 $grid-gutter-width;*/
  margin-top: $grid-gutter-width/2;
  /*&:first-child {
    padding-left: $grid-gutter-width/2;
  }
  @include media-breakpoint-down(md) {
    padding: 0 $grid-gutter-width/2;
  }*/
}

.grwrap {
  padding-left: 1rem;
  font-size: 82.4%;
}

.grdesc {
  margin-left: -1rem;
  font-family: $font-family-bold;
}

.form-control {
  box-shadow: none;
  color: $black;
}
.hypocalc,
.tx-pkbs-pension-calculator {
  select.form-control {
    text-align-last: right;
    option {
      direction: initial;
    }
  }
}

select.form-control {
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
  padding: .45rem 1.75rem .45rem .75rem;
  background: $custom-select-bg $custom-select-background;
  // Hides the default caret in IE11
  &::-ms-expand {
    display: none;
  }
}

.dropup .dropdown-toggle::after,
.dropdown-toggle::after {
  border: none;
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  font-size: 2rem;
  line-height: 1rem;
}

.dropup .dropdown-toggle::after {
  content: "";
}

.c-form {
  .bootstrap-select {
    @include media-breakpoint-down(md) {
      width: 100% !important;
    }
  }
}

.bootstrap-select {
  .btn-light {
    font-family: $font-family-base;
    background: $white;
    border-color: $gray-445;
    box-shadow: none;
    &:hover {
      background: $primary;
      color: $white;
      font-family: $font-family-bold;
    }
  }
  &.show {
    .btn-light {
      background: $primary;
      color: $white;
      font-family: $font-family-bold;
    }
  }
  .dropdown-item {
    &:hover {
      background: $primary;
      color: $white;
      font-family: $font-family-bold;
    }
  }
}

.py-2r {
  padding: 2rem 0;
}

.page-link-x {
  &:hover {
    color: $body-color;
    text-decoration: underline;
    .fal {
      text-decoration: underline;
    }
  }
}
.page-item.active {
  .page-link-x {
    color: $body-color;
    font-family: $font-family-bold;
    text-decoration: underline;
  }
}

.btn {
  font-weight: $font-weight-base;
  font-family: $font-family-bold;
}
.btn .svg-icon {
  width: 2.2rem;
  height: 2.2rem;
}
.btn-primary {
  .svg-icon {
    --color1: #ffffff;
    --color1neg: #3b7d98;
    margin-left: -.5rem;
  }
  &:hover {
    .svg-icon {
      --color1: #36728C;
      --color1neg: #fff;
    }
  }
}

.btn-outline-primary {
  .svg-icon {
    --color1: #36728C;
    --color1neg: #fff;
    margin-left: -.5rem;
  }
  &:hover {
    .svg-icon {
      --color1: #ffffff;
      --color1neg: #3b7d98;
    }
  }
}
.btn-gray-220 {
  color: $gray-650;
  &:hover {
    color: $primary;
    background: $white;
    border-color: $primary;
    .svg-icon {
      --color1: #36728C;
      --color1neg: #fff;
    }
  }
}

.btn-text {
  .svg-icon {
    --color1: #36728C;
    --color1neg: #fff;
    margin-left: -.5rem;
  }
  &:hover {
    .svg-icon {
      --color1: #8c8c8c;
      --color1neg: #fff;
    }
  }
}

.input-group .btn {
  line-height: $input-btn-line-height;
  .svg-icon {
    margin-left: 0;
  }
}

.container .container.content-container {
  padding-left: 0;
  padding-right: 0;
}

.form-control:focus {
  box-shadow: none;
}

.table-stripedx td:nth-of-type(even),
.table-stripedx th:nth-of-type(even){
  background: $gray-150;
}
.table-stripedx thead th {
  color: $primary;
}
.table th,
.table-stripedx th {
  font-family: $font-family-bold;
  font-weight: normal;
}
.text-bold {
  font-family: $font-family-bold;
  font-weight: normal;
}
.table-stripedx tr:last-child th,
.table-stripedx tr:last-child td {
  border-bottom: 1px solid $gray-420;
}
.ce-table tr:last-child th,
.ce-table tr:last-child td {
  border-bottom: 1px solid $gray-420;
}
tr.blackline th,
tr.blackline td {
  border-bottom-color: $black;
  border-bottom: 2px solid $black !important;
}
tr.subblackline th,
tr.subblackline td {
  border-bottom-color: $black;
  border-bottom: 1px solid $black !important;
}
.imageinratio {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.imageinratio.focuspoint:before {
  display: block;
  content: "";
  padding-top: 56.25%;
}
.dsgvo-label a {
  text-decoration: underline;
}
.main-footer {
  a[href^=javascript],
  a[href^=tel],
  a[href^=sms],
  a[href^=mailto] {
    text-decoration: underline;
  }
}
.alert {
  font-family: $font-family-bold;
  font-weight: $font-weight-normal;
  color: $body-color;
}
.alert-danger {
  background-color: #f8ba94;
}
.alert-success {
  background-color: #c5d4a6;
}

.mt-10p {
  margin-top: 10px;
}

.form-control-md {
  height: $input-height-md;
  padding: $input-padding-y-md $input-padding-x-md;
  @include font-size($input-font-size-md);
  line-height: $input-line-height-md;
  @include border-radius($input-border-radius-md);
}

.cookiecontent a {
  color: $black;
  text-decoration: underline;
}

.inlineimage {
  max-width: 50%;
}

#gdrp-cookieoverlay {
  background: $white !important;
  border-color: #a4a4a4 !important;
}
.compensate-for-scrollbar {
  margin-right: 0;
}

.table {
  margin-bottom: 0;
}

.table thead th {
  border-top: 0;
  border-bottom: 0;
}

.table td, .table th {
  padding: .55rem .4rem;
}

.table td:first-child,
.table th:first-child{
  padding-left: 0;
}
.table td:last-child,
.table th:last-child{
  padding-right: 0;
}

.tooltip {
  ul.dotted {
    li:before {
      color: $white;
    }
  }
}

.tooltip {
  ul.dotted {
    li:before {
      color: $white;
    }
  }
}

.border-bottom-thicker {
  border-bottom-width: 2px !important;
}
.border-thicker {
  border-width: 2px !important;
}

.tooltip {
  ul.dotted {
    li:before {
      color: $white;
    }
  }
}


.tooltip {
  ul.dotted {
    li:before {
      color: $white;
    }
  }
}

