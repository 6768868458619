.accordion {
  .svg-icon {
	color: $primary;
	transition: all 0.3s ease-in-out;
	width: 2rem;
	height: 2rem;
	--color1: #36728C;
  }
  .svg-icon.minus {
	display: none;
  }
  .accordion-title {
	&:hover {
	  background: $primary;
	  .svg-icon {
		color: $white;
		--color1: #ffffff;
	  }
	}
	&:last-child {
	  border-bottom: 1px solid $primary;
	}
	.acbtn {
	  border-top: 1px solid $primary;
	  cursor: pointer;
	  > h5 {
		color: $primary;
	  }
	  &:hover {
		> h5 {
		  color: $white;
		}
	  }
    @media print {
      width: 100%;
    }
	}
	&.open {
	  &:hover {
		background: $white;
		color: inherit;
		.svg-icon {
		  color: $primary;
		  --color1: #36728C;
		}
		.acbtn {
		  > h5 {
			color: $primary;
		  }
		}
	  }
	  background: $white;
	  .svg-icon {
		display: none;
	  }
	  .svg-icon.minus {
		display: block;
		color: $primary;
	  }
	}
  .card-body {
    h5 {
      margin-bottom: .5rem !important;
    }
  }
  }
}
